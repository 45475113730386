<template>
  <no-subcription v-if="!getSubscription"/>
    <div v-else class="page-add-inventory">
        <app-loading v-show="loadingData"/>
        <v-container v-if="!loadingData">
            <v-card>
                <v-card-title>
          <p>{{
                  $vuetify.lang.t("$vuetify.menu.summary")
              }}</p>
                </v-card-title>
                <v-card-text>
                  <resume-filter-component @updateFilter="loadData"/>
                  <sale-summary :products-data="getSalesProductData" :currency="user.company.currency" :first-date="firstDate" :second-date="secondDate"/>
                  <p>En el intervalo desde {{ firstDate.toLocaleDateString() + ' ' + firstDate.getHours() + ':' + firstDate.getMinutes() }}
                    hasta  {{ secondDate.toLocaleDateString() + ' ' + secondDate.getHours() + ':' + secondDate.getMinutes() }},
                    no se han concluido ventas(en estado Aceptada)</p>
                    <p v-if="localSalesByProductMargin.length > 0">El(s) artículo(s) con mayor margen de beneficio(s):</p>
                  <list-products v-if="localSalesByProductMargin.length > 0" :products="localSalesByProductMargin.slice( 0, sliderModel)"  :currency="user.company.currency" :first-date="firstDate" :second-date="secondDate"/>
                    <p v-if="getSalesByProducts.length > 0">El(s) artículo(s) más vendido(s):</p>
                  <list-products :products="getSalesByProducts.slice( 0, sliderModel)" :first-date="firstDate" :second-date="secondDate" :currency="user.company.currency"/>
                    <p v-if="localSalesByEmployer.length > 0">El(s) empleado(s) con más venta(s):</p>
                    <employee-sumary :sale-employer="localSalesByEmployer.slice(0,sliderModel)" :currency="user.company.currency"/>
                    <p v-if="localSalesByPayments.length > 0">El(s) método(s) de pago por el que más se cotiza:</p>
                    <payment-summary :payments="localSalesByPayments" :currency="user.company.currency"/>
                    <p v-if="localSalesByMark.length > 0">La(s) marca(s) que más se cotiza:</p>
                    <mark-summary :marks="localSalesByMark" :currency="user.company.currency"/>
                    <p v-if="localSalesByOrder.length > 0">El(s) tipo(s) de orden más usado es:</p>
                    <order-summary :orders="localSalesByOrder.slice(0, sliderModel)" :currency="user.company.currency"/>
                </v-card-text>
            </v-card>
        </v-container>
    </div>
</template>
<script>
import AppLoading from '../../../components/core/AppLoading'
import { mapActions, mapGetters, mapState } from 'vuex'
import ResumeFilterComponent from './ResumeFilterComponent'
import ListProducts from './summary/ProductsSummary'
import SaleSummary from './summary/SaleSummary'
import EmployeeSumary from './summary/EmployeeSumary'
import PaymentSummary from './summary/PaymentSumary'
import MarkSummary from './summary/MarkSummary'
import OrderSummary from './summary/OrderSummary'

export default {
  name: 'Summary',
  components: { OrderSummary, MarkSummary, PaymentSummary, EmployeeSumary, SaleSummary, ListProducts, ResumeFilterComponent, AppLoading },
  data () {
    return {
      min: 0,
      max: 5,
      sliderModel: 5,
      loadingData: false,
      panel: [0],
      seriesData: [],
      chartOptions: {},
      vBindOption: {
        itemKey: 'no_facture',
        singleExpand: false,
        showExpand: false
      },
      menu: false,
      formRule: this.$rules,
      firstDate: new Date(),
      secondDate: new Date(),
      localShops: [],
      localSalesByOrder: [],
      localSalesByEmployer: [],
      localSalesByPayments: [],
      localSalesByMark: [],
      localSalesByProduct: [],
      localSalesByProductMargin: []
    }
  },
  props: {
    online: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('sale', ['salesByEmployer', 'salesByPayments', 'salesByProducts', 'salesByTypeOrder', 'salesByMark', 'isTableLoading']),
    ...mapGetters('auth', ['user']),
    ...mapGetters('sale', ['getSalesProductData', 'getSalesByProductsMargin', 'getSalesByProducts']),
    ...mapState('auth', ['company_subscription']),
    getSubscription () {
      return this.company_subscription ? !this.company_subscription.is_caduced : false
    }
  },
  methods: {
    ...mapActions('sale', ['getSaleSummary']),
    async loadData (firstDate, secondDate, localShop) {
      if (firstDate && secondDate && localShop.length > 0) {
        this.firstDate = firstDate
        this.secondDate = secondDate
        await this.getSaleSummary({
          category: this.$route.params.categoryId,
          shops: localShop,
          dates: [this.firstDate, this.secondDate],
          online: this.$route.path.split('online').length > 1
        }).then(() => {
          if (this.salesByEmployer) {
            this.localSalesByEmployer = this.salesByEmployer.sort(function (a, b) {
              if (a.netPrice > b.netPrice) return -1
              if (a.netPrice < b.netPrice) return 1
              return 0
            })
          }
          if (this.salesByTypeOrder) {
            this.localSalesByOrder =
              this.salesByTypeOrder.sort(function (a, b) {
                if (a.totalPrice > b.totalPrice) return -1
                if (a.totalPrice < b.totalPrice) return 1
                return 0
              })
          }
          this.localSalesByPayments = this.salesByPayments.sort(function (a, b) {
            if (a.totalPrice > b.totalPrice) return -1
            if (a.totalPrice < b.totalPrice) return 1
            return 0
          })
          this.localSalesByMark = this.salesByMark.sort(function (a, b) {
            if (a.totalPrice > b.totalPrice) return -1
            if (a.totalPrice < b.totalPrice) return 1
            return 0
          })
          this.localSalesByProductMargin =
            this.getSalesByProductsMargin.sort(function (a, b) {
              if ((a.netPrice - a.totalCost - a.totalTax - a.totalDiscount) > (b.netPrice - b.totalCost - b.totalTax - b.totalDiscount)) return -1
              if ((a.netPrice - a.totalCost - a.totalTax - a.totalDiscount) < (b.netPrice - b.totalCost - b.totalTax - b.totalDiscount)) return 1
              return 0
            })
        })
      }
    }
  }
}
</script>

<style scoped></style>
