<template>
  <v-list-item-group color="primary">
    <v-list-item v-for="(item, i) in orders" :key="i">
      <v-list-item-icon>
        <v-chip :color=" item.color " dark>
          {{ item.color }}
        </v-chip>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          <p>{{item.name !== '' ? item.name : $vuetify.lang.t('$vuetify.no_defined')}}</p>
        </v-list-item-title>
        <v-list-item-subtitle
          v-text="
                                  `${parseFloat(item.totalPrice).toFixed(2) + ' ' + currency}`
                                "
        />
      </v-list-item-content>
    </v-list-item>
  </v-list-item-group>
</template>

<script>
export default {
  name: 'OrderSummary',
  props: {
    orders: {
      type: Array,
      default: function () {
        return []
      }
    },
    currency: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>

</style>
